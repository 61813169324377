import React, { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";

import { SideMenu } from "../components/sideMenu.component";

export const MainPage = (props) => {

    useEffect(() => {
        props.navigate('users');
    }, []);

    return (
        <div className='mainPage'>
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <Link className="logo" to={'/'}>
                        <img src='logo192.png' alt='logo' />
                    </Link>
                    <div className='headerRightBlock'>
                    </div>
                </div>
            </nav>
            <div className='contentWrapper'>
                <SideMenu />
                <div className='mainBlock'>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};