import React from 'react';
import Modal from 'react-bootstrap/Modal';

export const ModalDialog = (props) => {

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            keyboard={false}
            centered>
            <Modal.Header closeButton>
                <div className='pageTitle'>
                    <span>{props.title}</span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <button
                    className='btn mainBtn cancelBtn'
                    onClick={props.onHide}>Cancel</button>
                <button
                    className='btn mainBtn acceptBtn'
                    onClick={props.onAccept}>{props.acceptBtnText}</button>
            </Modal.Footer>
        </Modal>
    )
};