import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { FormData } from '../helpers/FormData';
import { _tariffPlanAdd, _tariffPlanEdit } from '../services/apiConnections.service';

export const dateToLocalISO = (date) => {
    let timeZoneOffset = (new Date(date)).getTimezoneOffset() * 60000;
    let localISOTime = (new Date(date - timeZoneOffset)).toISOString();
    return localISOTime;
};

export const TariffPlanUpsert = (props) => {

    const [error, setError] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        name: '',
        destination: [],
        deny_destination: [],
        price: '',
        sms_limit: '',
        validity_time: '',
        start_date: dateToLocalISO(new Date()),
        end_date: dateToLocalISO(new Date()),
        description: ''
    });
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        handleFormValueChange('start_date', dateToLocalISO(startDate));
    }, [startDate]);

    useEffect(() => {
        handleFormValueChange('end_date', dateToLocalISO(endDate));
    }, [endDate]);

    let navigate = useNavigate();
    let params = useParams();

    const handleUpsert = async (event) => {
        event.preventDefault();
        let result;

        if (!props.edit)
            result = await _tariffPlanAdd(formValues);
        else
            result = await _tariffPlanEdit(formValues, params.id);

        if (result.error) {
            setError(result.error);
        } else {
            console.log("success");
            navigate('/tariffs');
        }
    };

    const handleValidation = (target) => {
        switch (target.name) {
            case 'name':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        name: "Cannot be empty"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['name'];
                    setFormErrors(copy);
                }
                break;
            case 'destination':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        destination: "Cannot be empty"
                    }));
                } else if (!/^[\d][\d,]*$/.test(target.value)) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        destination: "Destination has to be numbers with comma as divider"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['destination'];
                    setFormErrors(copy);
                }
                break;
            case 'deny_destination':
                if (target.value.length && !/^[\d][\d,]*$/.test(target.value)) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        deny_destination: "Deny Destination has to be numbers with comma as divider"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['deny_destination'];
                    setFormErrors(copy);
                }
                break;
            case 'price':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        price: "Cannot be empty"
                    }));
                } else if (!/^\d+\.\d{1,2}$/.test(target.value)) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        price: "Price has to be in format *.**"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['price'];
                    setFormErrors(copy);
                }
                break;
            case 'sms_limit':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        sms_limit: "Cannot be empty"
                    }));
                } else if (!/^\d+$/.test(target.value)) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        sms_limit: "Only digits are available"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['sms_limit'];
                    setFormErrors(copy);
                }
                break;
            case 'validity_time':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        validity_time: "Cannot be empty"
                    }));
                } else if (!/^\d+$/.test(target.value)) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        validity_time: "Only digits are available"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['validity_time'];
                    setFormErrors(copy);
                }
                break;
            case 'description':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        description: "Cannot be empty"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['description'];
                    setFormErrors(copy);
                }
                break;

            default: break;
        }
    };

    const formIsValid = () => {
        return !Object.keys(formErrors).length && formValues.name.length && formValues.destination.length &&
            formValues.price.length && formValues.sms_limit.length && formValues.description.length && formValues.price.length &&
            formValues.sms_limit.length && formValues.validity_time.length;
    };

    const parseDestinations = (destinations) => {
        return destinations.split(",");
    };

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>{!props.edit ? 'Add Tariff Plan' : 'Edit Tariff Plan'}</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='pageContent'>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='name'>Name</label>
                        <div className='inputWrapper'>
                            <input
                                id='name'
                                name='name'
                                type="text"
                                className={`formInput ${formErrors.name ? 'invalidInput' : ''}`}
                                placeholder="Name Tariff Plan"
                                value={formValues.name}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'name',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.name}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='destination'>Destination</label>
                        <div className='inputWrapper'>
                            <input
                                id='destination'
                                name='destination'
                                type="text"
                                className={`formInput ${formErrors.destination ? 'invalidInput' : ''}`}
                                placeholder="Destination"
                                value={formValues.destination}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'destination',
                                        parseDestinations(event.target.value)
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.destination}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel'
                            htmlFor='deny_destination'>Deny Destination</label>
                        <div className='inputWrapper'>
                            <input
                                id='deny_destination'
                                name='deny_destination'
                                type="text"
                                className={`formInput ${formErrors.deny_destination ? 'invalidInput' : ''}`}
                                placeholder="Deny Destination"
                                value={formValues.deny_destination}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'deny_destination',
                                        parseDestinations(event.target.value)
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.deny_destination}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='price'>Price</label>
                        <div className='inputWrapper'>
                            <input
                                id='price'
                                name='price'
                                type="text"
                                className={`formInput ${formErrors.price ? 'invalidInput' : ''}`}
                                placeholder="Price"
                                value={formValues.price}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'price',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.price}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='sms_limit'>SMS limit</label>
                        <div className='inputWrapper'>
                            <input
                                id='sms_limit'
                                name='sms_limit'
                                type="text"
                                className={`formInput ${formErrors.sms_limit ? 'invalidInput' : ''}`}
                                placeholder="SMS Limit"
                                value={formValues.sms_limit}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'sms_limit',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.sms_limit}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='validity_time'>Validity Time</label>
                        <div className='inputWrapper'>
                            <input
                                id='validity_time'
                                name='validity_time'
                                type="text"
                                className={`formInput ${formErrors.validity_time ? 'invalidInput' : ''}`}
                                placeholder="Validity Time"
                                value={formValues.validity_time}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'validity_time',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.validity_time}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className='inputWrapper dateTimePickerBlock'>
                            <div className='col-6'>
                                <label className='inputLabel'>Start Date</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {
                                        setStartDate(new Date(date));
                                    }}
                                    selectsStart
                                    dateFormat="MMMM d, yyyy"
                                    minDate={new Date()}
                                    maxDate={endDate}
                                    className='formInput datePicker'
                                />
                            </div>
                            <div className='col-6'>
                                <label className='inputLabel'>End Date</label>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => {
                                        setEndDate(new Date(date));
                                    }}
                                    selectsEnd
                                    dateFormat="MMMM d, yyyy"
                                    minDate={startDate}
                                    className='formInput datePicker'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='description'>Description</label>
                        <div className='inputWrapper'>
                            <input
                                id='description'
                                name='description'
                                type="text"
                                className={`formInput ${formErrors.description ? 'invalidInput' : ''}`}
                                placeholder="Description"
                                value={formValues.description}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'description',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.description}</span>
                        </div>
                    </div>
                    <div className='buttonBlock'>
                        <button
                            onClick={handleUpsert}
                            className={`mainBtn btn ${formIsValid() ? '' : 'disabledBtn'}`}
                            disabled={!formIsValid()}>{props.edit ? 'Save' : 'Add'}</button>
                    </div>
                </div>
            </div>
        </div >
    );
};